import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './App.scss';
import { AppStoreIcon } from "./Icons/AppStoreIcon";
import { faCalculator, faTag, faSave, faPen, faHistory, faUndo } from '@fortawesome/pro-light-svg-icons'
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

interface FeatureProps {
    icon: IconDefinition;
    title: string;
    description: string;
}

function Feature(props: FeatureProps) {
    return <div className="feature">
        <FontAwesomeIcon icon={props.icon} size="1x" scale={240}/>
        <section>
            <div className="title">{props.title}</div>
            <div className="description">{props.description}</div>
        </section>
    </div>;
}

function AppStoreButton() {
    return <a href="https://apps.apple.com/app/calculator-q/id1598403298"><AppStoreIcon/></a>
}

function PlayStoreButton() {
    return <a href="https://play.google.com/store/apps/details?id=com.code529.com.calculatorqt8"><img src="images/playstore-icon.png"/></a>
}

function App() {
    return (
        <div className="app">
            <div className="home">
                <div className="header">
                    <div className="coming-soon">Now available on IOS and Android.</div>
                    {/*<div className="coming-soon"><a href="https://testflight.apple.com/join/En0eJsFg"><b>Join public IOS Beta.</b></a></div>*/}
                    {/*<div className="coming-soon"><a href="https://play.google.com/store/apps/details?id=com.code529.com.calculatorqt8"><b>Join public Android Beta.</b></a></div>*/}
                    {/*<div className="coming-soon"><a href="mailto:info@code529.com"><b>Join public Android Beta.</b></a></div>*/}
                    <div className="menubar">
                        <div className="title">CALCULATOR Q</div>
                        <div className="menu">
                            <span>HOME</span>
                            <span>FEATURES</span>
                            <span>CONTACT</span>
                            <span>GET</span>
                        </div>
                        <div className="action">
                           <AppStoreButton/>
                           <PlayStoreButton/>
                        </div>
                    </div>
                    <div className="hero">
                        <div className="title">More than a calculator</div>
                        <div className="description">
                            <p>Calculate, label, edit, save...</p>
                            <p>Calculator Q not only allows you to perform the typical calculator operations but offers
                                editable history and allows you to label and reference previous calculations.</p><p>Save your calculations, including history, to reuse later.</p>
                        </div>
                        <div className="action"><AppStoreButton/><PlayStoreButton/></div>
                        <div className="screenshot"><img src="/images/screenshot1.png"/></div>
                    </div>
                    <div className="hero-footer-container">
                        <div className="hero-footer">
                            {/*<img src="/images/appstore-icon.png"/>*/}
                        </div>
                    </div>
                </div>
                <div className="screenshot-container">
                    <img src="/images/screenshot1.png"/>
                </div>
                <div className="features-container">
                    <div className="title">Features</div>
                    <div className="features">
                        <div className="column">
                            <Feature icon={faCalculator} title="Calculate"
                                     description="Perform calculations like a regular calculator. Scientific keyboard coming soon."/>
                            <Feature icon={faTag} title="Label"
                                     description="Apply Labels and use as reference in other calculations."/>
                            <Feature icon={faSave} title="Save"
                                     description="Save your calculations including history for reuse later."/>
                        </div>
                        <div className="column">
                            <Feature icon={faHistory} title="History"
                                     description="Previous calculations are visible and editable in the history tape."/>
                            <Feature icon={faPen} title="Edit"
                                     description="Select previous operations in the history and edit."/>
                            <Feature icon={faUndo} title="Undo" description="Undo any mistakes."/>
                        </div>
                    </div>
                </div>
                <div className="footer-container">
                    <div className="footer">
                        <div className="column">
                            <a href="mailto:calculatorq@code529.com">Contact</a>
                            <a href="/#/terms-of-service">Terms of service</a>
                            <a href="/#/privacy-policy">Privacy policy</a>
                        </div>
                    </div>
                    <div className="footer-note"><span>Copyright © 2022 Dennis Van Roeyen. All rights reserved.</span></div>
                </div>
            </div>
        </div>
    );
}

export default App;
